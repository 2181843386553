/* Let's get this party started */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #f73e5a;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 0, 0, 0.4);
}

.dataResponse{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.graphicResponse{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    /* height: 100%; */
}

/* .leftArrowIconDiv{
    width: 0%;
}

.leftArrowIcon{
    color: #c30c26;
}

.leftArrowIcon:hover{
    cursor: pointer;
    color: #f73e5a;
} */


.divError {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.semaforoWrapper{
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f73e5a;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    margin: auto;
}

.textInfos{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.statoDesktop{
    align-self: center;
    width: 100%;
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
}

.statoMobile{
    display: none;
}

.semaforo{
    position: relative;
    background: #34495E;
    width: 20%;
    height: 50%;
    align-self: center;
    border-radius: .2em;
    z-index: 10;
}

.innerLuce1{
    position: absolute;
    background: #223440;
    z-index: 1;
    width: 78%;
    height: 29%;
    border-radius: .1em;
    top: 5%;
    left: 11%;
}

.luce1{
    position: absolute;
    background: #adadad;
    z-index: 2;
    width: 50%;
    height: 20%;
    border-radius: 100%;
    top: 10%;
    left: 25%;
}

.innerLuce2{
    position: absolute;
    background: #223440;
    z-index: 1;
    width: 80%;
    height: 30%;
    border-radius: .1em;
    top: 35%;
    left: 10%;
}

.luce2{
    position: absolute;
    z-index: 2;
    background: #adadad;
    width: 50%;
    height: 20%;
    border-radius: 100%;
    top: 40%;
    left: 25%;
}

.innerLuce3{
    position: absolute;
    background: #223440;
    z-index: 1;
    width: 80%;
    height: 28%;
    border-radius: .1em;
    top: 67%;
    left: 10%;
}

.luce3{
    position: absolute;
    z-index: 2;
    background: #adadad;
    width: 50%;
    height: 20%;
    border-radius: 100%;
    top: 70%;
    left: 25%;
}

.progressBarWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}

progress {
    border-radius: 7px; 
    width: 80%;
    height: 15px;
}

progress::-webkit-progress-bar {
    background-color: #bdbdbd;
    border-radius: 7px;
}

progress::-webkit-progress-value {
    background-color: #fff;
    border-radius: 7px;
}

progress::-moz-progress-bar {
    background-color: #bdbdbd;
    border-radius: 7px;
}

.progressBarRed {
    border-radius: 7px; 
    width: 80%;
    height: 15px;
}

.progressBarRed::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 7px;
}

.progressBarRed::-webkit-progress-value {
    background-color: #f73e5a;
    border-radius: 7px;
}

.progressBarRed::-moz-progress-bar {
    background-color: #f73e5a;
    border-radius: 7px;
}

.progressBarHalfGreen{
    border-radius: 7px; 
    width: 80%;
    height: 15px;
}

.progressBarHalfGreen::-webkit-progress-bar {
    background-color: #e4e4e4;
    border-radius: 7px;
}

.progressBarHalfGreen::-webkit-progress-value {
    background-color: #369e28;
    border-radius: 7px;
}

.progressBarHalfGreen::-moz-progress-bar {
    background-color: #369e28;
    border-radius: 7px;
}

.progressBarBlue{
    border-radius: 7px; 
    width: 80%;
    height: 15px;
}

.progressBarBlue::-webkit-progress-bar {
    background-color: #e4e4e4;
    border-radius: 7px;
}

.progressBarBlue::-webkit-progress-value {
    background-color: #3e6ac7;
    border-radius: 7px;
}

.progressBarBlue::-moz-progress-bar {
    background-color: #3e6ac7;
    border-radius: 7px;
}

.progressBarFullGreen{
    border-radius: 7px; 
    width: 80%;
    height: 15px;
}

.progressBarFullGreen::-webkit-progress-bar {
    background-color: #e4e4e4;
    border-radius: 7px;
}

.progressBarFullGreen::-webkit-progress-value {
    background-color: #369e28;
    border-radius: 7px;
}

.progressBarFullGreen::-moz-progress-bar {
    background-color: #369e28;
    border-radius: 7px;
}

.dataWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 1.2em;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 2em 0;
}

.dataWrapper p {
    padding: .2em;
}

.dataWrapper span {
    font-weight: lighter;
}

.dataWrapper data {
    font-weight: bold;
}

.dataID {
    position: relative;
    animation: slide .4s ease-in-out 1;
    text-transform: capitalize;
}

.dataIndirizzo {
    position: relative;
    animation: slide .5s ease-in-out 1;
    text-transform: capitalize;
}

.dataCivico {
    position: relative;
    animation: slide .6s ease-in-out 1;
    text-transform: capitalize;
}

.dataQuartiere {
    position: relative;
    animation: slide .7s ease-in-out 1;
    text-transform: capitalize;
}

.dataDataInoltro {
    position: relative;
    animation: slide .6s ease-in-out 1;
    text-transform: capitalize;
}

.dataDataChiusura {
    position: relative;
    animation: slide .6s ease-in-out 1;
    text-transform: capitalize;
}

.btnContainer{
    width: 100%;
    display: flex;
}

.btnGoBackContainer{
    width: 80%;
    display: flex;
    justify-content: center;
}

.buttonGoBack{
    background-color: #f73e5a;
    color: #fff;    
    width: 22%;
    align-self: center;
    height: 40px;
    border: 0;
    border-radius: 1em;
    font-weight: 600;
}

.buttonGoBack:hover{
    cursor: pointer;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.emailButtonContainer{
    width: 20%;
}

.emailButton{
    height: 40px;
    background-color: #f73e5a;
    border-radius: 1em;
    border: 0;
}

.emailButton:hover{
    cursor: pointer;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.emailButtonContainer img{
    width: 70%;
}

.modalButtonContainer{
    display: flex;
    justify-content: space-around;
    margin: 2em 0 .5em;
}

.policyBreveContainer{
    display: block;
    overflow-x: auto;
    height: 10em;
    margin-top: 2em;
}

.privacyPolicyBreveTitle{
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 1em;
}

.privacyPolicyBreveText{
    line-height: 1.3em;
}

@keyframes slide {
    0% {
        right: -0.5vw;
        visibility: hidden;
        opacity: 0
    }
    100% {
        right: 0vw;
        opacity: 1
    }
}

.dataNotFound {
    align-self: center;
    color: #E52420;
    margin-bottom: 5em;
    font-size: 1.3em;
}

.spinnerIcon {
    align-self: center;
    margin: auto;
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@media screen 
    and (min-width: 1200px)
    and (max-width: 1600px) {

        .semaforoWrapper{
            margin-top: auto;
            width: 120px;
            height: 120px;
        }

        .statoDesktop{
            font-size: 1.2em;
        }

        .buttonGoBack{
            width: 30%;
        }

        .emailButtonContainer img{
            width: 60%;
        }

}

@media screen and (max-width: 1024px) {

    /* .dataResponse{
        height: 80vh;
    } */

    .graphicResponse{
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: 1em;
    }

    /* .leftArrowIconDiv{
        margin-bottom: 2em;
    }

    .leftArrowIcon{
        width: 100%;
        padding: 1em 0 0 1em;
    } */

    .semaforoWrapper{
        width: 45%;
        /* height: 100%; */
        margin: 0 auto;
    }

    .textInfos{
        width: 100%;
    }

    .statoDesktop{
        display: none;
    }

    .statoMobile{
        display: flex;
        align-self: center;
        margin-top: 2em;
        font-size: 1.3em;
        font-weight: bold;
        text-align: center;
    }

    .progressBarWrapper{
        padding-top: 2em;
    }

    .semaforo{
        align-self: center;
        width: 35%;
        height: 75%;
    }

    .innerLuce1{
        width: 80%;
        height: 28%;
        top: 5%;
        left: 10%; 
    }

    .dataWrapper{
        margin-bottom: 2em;
        height: 50%;
        overflow-y: hidden;
    }

    .buttonGoBack{
        width: 30%;
        margin-bottom: 1em;
    }

    .spinnerIcon{
        margin: 1em auto;
    }
} 

@media screen and (min-width: 1920px){

    .emailButtonContainer{
        height: fit-content;
    }

    .emailButtonContainer img{
        width: 45%;
    }
}