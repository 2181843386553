
.chartsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 60vh;
    margin-bottom: 2em;
}

.SegnalazioniGeticoContainer{
    display: flex;
    flex-wrap: wrap;
    width: 45%;
    background: #fff;
    border-radius: .5em;
    padding: 1em;
}

.legenda{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
}

.legendaElem1, .legendaElem2, .legendaElem3, .legendaElem4{
    background: #ECEFF7;
    align-self: flex-end;
    display: flex;
    width: 30%;
    justify-content: space-between;
    padding: .3em;
}

.legendaElem1{
    border-top-left-radius: .5em;
    border-top-right-radius: .5em;
}

.legendaElem4{
    border-bottom-left-radius: .5em;
    border-bottom-right-radius: .5em;
}

.color1{
    width: 15px;
    height: 15px;
    background-color: #FEF266;
    border-radius: 100%;
    align-self: center;
}

.color2{
    width: 15px;
    height: 15px;
    background-color: #379634;
    border-radius: 100%;
    align-self: center;
}

.color3{
    width: 15px;
    height: 15px;
    background-color: #FF0000;
    border-radius: 100%;
    align-self: center;
}

.color4{
    width: 15px;
    height: 15px;
    background-color: #4F77AA;
    border-radius: 100%;
    align-self: center;
}

.graficoSegnalazioniGetico{
    margin: 0 auto;
    width: 55%;
    height: 55%;
    padding-bottom: 5em;
}

.segnalazioniChiuse{
    width: 45%;
    background: #fff;
    border-radius: .5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.segnalazioniChiuse h3{
    align-self: center;
    color: #f73e5a;
    font-size: 2em;
    font-weight: bold;
}

.segnalazioniChiuse p{
    align-self: center;
    color: #c30c26;
    font-size: 3em;
    font-weight: bold;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .legendaElem1, .legendaElem2, .legendaElem3, .legendaElem4{
        width: 40%;
        font-size: .9em;
        font-weight: 400;
    }

    .graficoSegnalazioniGetico{
        width: 60%;
        height: 60%;
        padding-bottom: 3em;
    }
}

@media screen and (max-width: 1024px) {

    .chartsContainer{
        flex-direction: column;
        width: 100%;
    }

    .SegnalazioniGeticoContainer{
        width: 91%;
        height: 45vh;
        border-radius: 2em;
    }

    .legenda{
        width: 100%;
        height: 10vh;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
    }

    .legendaElem1{
        width: 70%;
        border-radius: .5em;
        margin: 1em auto;
    }

    .legendaElem2, .legendaElem3, .legendaElem4{
        flex-direction: column;
        align-self: center;
        justify-content: center;
        width: 25%;
        border-radius: .5em;
        font-size: .9em;
        margin: 1em auto;
    }

    .legendaElem1 p, .legendaElem2 p, .legendaElem3 p, .legendaElem4 p{
        align-self: center;
    }

    .legendaElem2 p, .legendaElem3 p, .legendaElem4 p{
        margin-bottom: .5em;
    }

    .graficoSegnalazioniGetico{
        /* margin: 3em auto; */
        width: 58%;
        height: 58%;
        padding-top: 2em;
        padding-bottom: 0;
    }

    .segnalazioniChiuse{
        border-radius: 2em;
        width: 91%;
        margin-top: 2em;
    }

    .segnalazioniChiuse h3{
        font-size: 1.8em;
        text-align: center;
    }

    .segnalazioniChiuse p{
        font-size: 3em;
        margin-top: 1em;
    }
}