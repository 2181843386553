
.footerDiv{
    width: 90%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    border-radius: .5em;
    padding: 1em;
}

.leftLogout{
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rightLogosDiv{
    width: 45%;
    display: flex;
    justify-content: space-evenly;
}

.logoaRegoladArte img{
    object-fit: contain;
}

.logoAvr img{
    object-fit: contain;
}

.logoFirenze img{
    object-fit: contain;
}

.logoutIcon{
    margin-right: .2em;
}

.policyFooter{
    text-decoration: none;
    color: #f73e5a;
    font-size: 1.1em;
    width: 30%;
    margin-left: 1em;
}

@media screen and (min-width: 1440px){
    .footerDiv{
        width: 94%;
        display: flex;
        justify-content: space-between;
        padding: 2em;
        border-radius: 2em;
    }
    
    .leftLogout{
        width: 30%;
        display: flex;
        align-items: center;
    }
    
    .rightLogosDiv{
        width: 30%;
        display: flex;
        justify-content: space-evenly;
    }
    
    .logoAvr{
        width: 100%;
    }
    
    .logoAvr img{
        width: 100%;
    }
    
    .logoFirenze{
        width: 100%;
    }
    
    .logoFirenze img{
        width: 100%;
    }
    
    .logoutIcon{
        margin-right: .5em;
    }
    
    .leftLogout:hover{
        cursor: pointer;
    }
    
    .logoutText:hover{
        text-decoration: underline;
    }

    .policyFooter{
        margin-left: 1em;
    }

    .policyFooter:hover{
        text-decoration: underline;
    }
}