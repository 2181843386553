
.divLoader{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.loaderContainer{
    width: 30%;
    height: 30%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.loaderContainer p{
    align-self: center;
    text-align: center;
    margin-top: 3em;
    font-size: 1.5em;
    font-weight: bold;
    color: #f73e5a;
}

.q1{
    animation-name: colora;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

.q2{
    animation-name: colora;
    animation-duration: 5s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}

.q3{
    animation-name: colora;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
}

.q4{
    animation-name: colora;
    animation-duration: 5s;
    animation-delay: 3s;
    animation-iteration-count: infinite;
}

.q5{
    animation-name: colora;
    animation-duration: 5s;
    animation-delay: 4s;
    animation-iteration-count: infinite;
}

@keyframes colora {
    0% {
        fill: #E2E2E2;
    }
    20% {
        fill: #f73e5a;
    }
    100% {
        fill: #E2E2E2;
    }
}

.punto1{
    animation-name: animaPunto;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    font-size: 1.5em;
}

.punto2{
    animation-name: animaPunto;
    animation-duration: 2s;
    animation-delay: .5s;
    animation-iteration-count: infinite;
    font-size: 1.5em;
}

.punto3{
    animation-name: animaPunto;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    font-size: 1.5em;
}

@keyframes animaPunto {
    0% { 
        color: #f73e5a;
    }
    20% { 
        color: #E2E2E2;
    }
    100% { 
        color: #f73e5a;
    }
}

.appContainer{
    width: 70%;
    margin: 2em auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1em;
}

.middleContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 75vh;
    margin-bottom: 2em;
}

@media screen and (min-width: 1200px) and (max-width: 1600px){
    .middleContainer{
        height: 85vh;
    }
}

@media screen and (max-width: 1024px) {

    .divLoader{
        width: 100%;
    }

    .loaderContainer{
        width: 90%;
        height: 50%;
    }

    .loaderContainer p{
        font-size: 1.8em;
    }

    .appContainer{
        width: 90%;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 1em;
        /* height: 100vh; */
        margin: 2em auto;
    }

    .middleContainer{
        width: 100%;
        height: 100%;
        flex-direction: column;
    }
}