
.rightContainer{
    display: flex;
    justify-content: center;
    width: 45%;
    padding: 5em;
    background: #c30c26;
    /* background: #c30c26 url('../../mappaFirenze.svg') no-repeat;
    background-size: contain;
    background-position: 150%; */
    border-top-right-radius: .5em;
    border-bottom-right-radius: .5em;
    perspective: 200px;
}

.fotoContainer{
    width: 100%;
    height: 100%;
    /* background: #c30c26 url('../../mappaFirenze.svg') no-repeat; */
    background-position: center;
    transition: transform 0.5s;
}

#firenze{
    width: 100%;
    height: 100%;
}

.textMappa{
    fill: #000;
}

.noGeomText{
    font-size: 1.6em;
    color: #fff;
    font-weight: bold;
    text-align: center;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .textMappa{
        font-weight: bold;
    }

    .noGeomText{
        font-size: 1.3em;
    }
}

@media screen and (max-width: 1024px) {
    .rightContainer{
        position: relative;
        margin-top: 2em;
        width: 100%;
        height: 300px;
        padding: 2em 0;
        perspective: none;
        border-radius: 0;
    }

    .fotoContainer{
        transition: transform 0.5s;
        width: 90%;
    }

    .textMappa{
        font-weight: bold;
    }

    .noGeomText{
        font-size: 1.2em;
    }
}