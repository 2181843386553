
.policyContainer{
    width: 80%;
    background: #fff;
    margin: 2em auto;
    padding: 1em;
}

.title{
    text-align: center;
    font-size: 2.5em;
    font-weight: bold;
    color: #f73e5a;
    margin: .5em 0 2em;
}

.policyTitle{
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1.4em;
}

.policySubTitle{
    font-size: 1.4em;
    margin-bottom: 3em;
}

.numberedListTitle{
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: .5em;
}

.numberedListText{
    font-size: 1.4em;
    margin-bottom: 2em;
    line-height: 1.2em;
}

.buttonEsciPolicy{
    background-color: #f73e5a;
    border: 0;
    font-size: 1.3em;
    color: #fff;
    padding: .5em;
    border-radius: .5em;
    width: 30%;
    margin: 0 0 0 6em;
}

@media screen and (min-width: 1440px){
    .policyContainer{
        width: 50%;
        padding: 4em;
    }

    .numberedListText{
        line-height: 1.4em;
    }

    .buttonEsciPolicy{
        width: 10%;
        margin: 0 0 0 85%;
    }

    .buttonEsciPolicy:hover{
        cursor: pointer;
        text-decoration: underline;
    }
}