

#mapid{
    width: 65%;
    border: 5px solid #fff;
    border-top-right-radius: .5em;
    border-bottom-right-radius: .5em;
}

.divLoading {
    display: flex;
    width: 65%;
    background: #fff;
}

@media screen and (max-width: 1024px) {
    #mapid{
        width: 97%;
        height: 350px;
        border-radius: .5em;
        margin-top: 2em;
    }

    .divLoading{
        width: 100%;
        border-radius: 2em;
        margin-top: 2em;
    }
}