
.leftContainer{
    padding: 4em 5em;
    width: 45%;
    background-color: #fff;
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
}

.leftWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1em;
}

/* .mobileH3{
    display: none;
} */

.textLeft h3{
    color: #f73e5a;
    font-size: 3em;
    font-weight: bold;
}

.textLeft p{
    color: #c30c26;
    font-size: 1.5em;
    align-self: center;
    padding-top: 1em;
    /* padding: 1em; */
}

.inputDiv{
    display: flex;
    justify-content: space-between;
    align-self: center;
    border: 2px solid #ECEFF7;
    border-radius: 1em;
    padding: 1.5em 1em;
    width: 100%;
}

.inputText{
    align-self: center;
    border: 0;
    height: 100%;
    font-size: 1.1em;
}

.inputDiv:focus-within{
    border: 2px solid #f73e5a;
}

.inputText:focus{
    outline: none;
}

.inputText::placeholder{
    color: rgb(138, 138, 138);
    font-size: 0.9em;
    font-weight: lighter;
}

.searchIcon{
    color: #a8a8a8;
    align-self: center;
}

.leftButton{
    width: 100%;
    display: flex;
    justify-content: center;
}

.datiContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
}

@media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 1) {

        .leftContainer{
            padding: 3em 5em;
        }

        .textLeft h3{
            color: #f73e5a;
            font-size: 3.1em;
            font-weight: bold;
        }

        .textLeft p{
            font-size: 1.2em;
            padding-top: 1em;
            padding-bottom: 1em;
            font-weight: lighter;
        }

        .leftButton{
            padding-bottom: 1em;
        }
}

@media screen and (max-width: 1024px) {
    .leftContainer{
        padding: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 2em;
    }

    .leftWrapper{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0;
    }

    /* .mobileH3{
        display: block;
        color: #f73e5a;
        font-size: 4em;
        font-weight: bold;
        padding: .5em;
        margin: 1em auto .5em;
    } */

    .textLeft h3{
        font-size: 2.5em;
        padding: .8em 0 0 .8em;
    }

    .textLeft p{
        font-size: 1.4em;
        padding: 1.5em;
        font-weight: lighter;
    }
    
    .inputDiv{
        display: flex;
        justify-content: space-between;
        align-self: center;
        border: 2px solid #ECEFF7;
        border-radius: 1em;
        padding: 1.5em 1em;
        width: 80%;
        margin: 0 auto;
    }
    
    .inputText{
        align-self: center;
        border: 0;
        height: 100%;
        font-size: 1.1em;
    }
    
    .inputDiv:focus-within{
        border: 2px solid #f73e5a;
    }
    
    .inputText:focus{
        outline: none;
    }
    
    .inputText::placeholder{
        color: rgb(138, 138, 138);
        font-size: 0.9em;
        font-weight: lighter;
    }
    
    .searchIcon{
        color: #a8a8a8;
        align-self: center;
    }
    
    .leftButton{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 0 2em 0;
    }

    .button{
        background-color: #f73e5a;
        color: #fff;    
        width: 40%;
        align-self: center;
        height: 50px;
        border: 0;
        border-radius: 1em;
        font-weight: 600;
        /* margin-top: 3em; */
        font-size: 1em;
    }
    
    .datiContainer{
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        padding: 1em .5em 0 .5em;
    }
}