
.loginWrapper{
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../../bgFirenze9.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    /* perspective: 100px; */
    
}

/* .fotoDavid{
    position: absolute;
    object-fit: contain;
    width: 50%;
    height: 200%;
    top: -40%;
    left: -7%;
    z-index: 1;
    transition: transform 1.2s;
} */

.fotoDavid{
    display: none
}

.loginContainer{
    position: relative;
    z-index: 2;
    width: 60vw;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3em auto;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 2em;
}

.loginH1{
    display: block;
    color: #f73e5a;
    font-size: 3em;
    font-weight: bold;
    padding: 1em;
    margin: 0 auto;
    text-align: center;
}

.loginContainer h3{
    color: #000;
    padding: 1em 0 0 2em;
    font-size: 1.5em;
    font-weight: lighter;
}

.LogosDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2em 1em;
}

.logoFirenzeLogin, .logoARegoladArteLogin, .logoAvrLogin{
    display: flex;
    justify-content: center;
    margin-bottom: .7em;
}

.logoFirenzeLogin img, .logoARegoladArteLogin img{
    object-fit: contain;
    width: 60%;
}

.logoAvrLogin img{
    object-fit: contain;
    text-align: center;
    width: 80px;
    height: 80px;
}


.loginContainer input{
    width: 80%;
    align-self: center;
    /* height: 50%; */
    border: 0;
    background-color: #F8F8F8;
    border-radius: 1em;
    padding: .5em;
    border-radius: 10px;
    font-size: 1.1em;
    margin: .5em 0;
}

.loginContainer input::placeholder{
    font-size: .8em;
    color: #a1a1a1;
}

.loginContainer input:focus{
    outline: none;
    background: #F8F8F8;
}

.button{
    background-color: #f73e5a;
    color: #fff;    
    width: 30%;
    align-self: center;
    height: 40px;
    border: 0;
    border-radius: 1em;
    font-weight: 600;
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover{
    cursor: pointer;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.errorMsg{
    background: #fff;
    padding: 1em;
    color: crimson;
    align-self: center;
    margin-top: 1em;
}

.linkPrivacyLogin{
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    margin: 0 0 2em 7em;
    background: none;
    border: 0;
}

/* @media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) {
        
        .loginContainer{
            margin: 1em auto;
        }
        
        .loginH1{
            margin: 0 auto .5em;
        }
    } */

@media screen and (min-width: 1440px){

    .loginWrapper{
        position: static;
        overflow: visible;
        perspective: none;
        /* height: 100%; */
        /* padding-bottom: 2em; */
    }

    .fotoDavid{
        display: none;
    }

    .loginContainer{
        position: static;
        width: 50%;
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto 2em;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        padding: 0;
        padding-bottom: 3em;
        margin-top: 5em;
    }

    .loginH1{
        display: block;
        color: #f73e5a;
        font-size: 3em;
        font-weight: bold;
        padding: .7em;
        margin: 0 auto .5em;
    }

    .LogosDiv{
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
    }

    .logoFirenzeLogin, .logoARegoladArteLogin, .logoAvrLogin{
        width: 30%;
    }
    
    .loginContainer input{
        width: 50%;
        align-self: center;
        /* height: 5%; */
        border: 0;
        background-color: #F8F8F8;
        border-radius: 1em;
        padding: .8em;
        border-radius: 10px;
        font-size: 1em;
        margin: .5em 0;
    }
    
    .loginContainer input:focus{
        outline: none;
        background: #F8F8F8;
    }

    .loginContainer input::placeholder{
        font-size: 1em;
    }
    
    .button{
        background-color: #f73e5a;
        color: #fff;    
        width: 25%;
        align-self: center;
        height: 40px;
        border: 0;
        border-radius: 1em;
        font-weight: 600;
        margin-top: 3em;
    }
    
    .button:hover{
        cursor: pointer;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    }
    
    .errorMsg{
        background: #fff;
        padding: 1em;
        color: crimson;
        align-self: center;
        margin-top: 1em;
    }

    .linkPrivacyLogin{
        margin: 0 0 0 85%;
        background-color: #f73e5a;
        font-size: 1.2em;
        padding: .7em;
        border-radius: 1em;
    }

    .linkPrivacyLogin:hover{
        text-decoration: underline;
        cursor: pointer;
    }

    /* .LogosDiv{
        display: flex;
        justify-content: space-evenly;
        padding: 2em 1em;
    }
    
    .logoAvrLogin{
        width: 100px;
        height: 100px;
    }
    
    .logoAvrLogin img{
        object-fit: contain;
        text-align: center;
        width: 100px;
        height: 100px;
    }
    
    .logoFirenzeLogin{
        width: 100px;
        height: 100px;
    }
    
    .logoFirenzeLogin img{
        object-fit: contain;
        text-align: center;
        width: 100px;
        height: 100px;
    } */
}

@media screen and (min-width: 1920px){

    .linkPrivacyLogin{
        margin: 10% 0 0 85%;
    }
}
