
.headerContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #fff;
    border-radius: .5em;
    margin-bottom: 2em;
}

.headerTextContainer{
    width: 40%;
    padding: 2em 6em;
}

.headerTextContainer h3{
    color: #f73e5a;
    font-size: 4em;
    font-weight: bold;
}

.headerTextContainer p{
    color: #c30c26;
    font-size: 1.333em;
    padding-top: .5em;
}

.headerTickets{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.headerTickets h5{
    color: #f73e5a;
    font-size: 1.6em;
    font-weight: bold;
}

.headerTickets p{
    color: #f73e5a;
    font-size: 1.3em;
    font-weight: bold;
    padding-bottom: 1em;
}

.headerTickets span{
    font-size: 2em;
    color: #c30c26;
    margin: 0 .1em;
}

/* .headerQuartierTickets{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.headerQuartierTickets h5{
    color: #f73e5a;
    font-size: 2em;
    font-weight: bold;
}

.headerQuartierTickets p{
    color: #c30c26;
    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: 1em;
} */

@media screen 
    and (min-device-width: 1200px) 
    and (max-device-width: 1600px) 
    and (-webkit-min-device-pixel-ratio: 1) {
        .headerTextContainer{
            width: 40%;
            padding: 2em 1em 2em 4em;
        }
    }

@media screen and (max-width: 1024px) {
    .headerContainer{
        flex-wrap: wrap;
        justify-content: space-around;
        border-radius: 2em;
    }

    .headerTextContainer{
        width: 100%;
        padding: 2em 2em 4em 2em;
    }

    .headerTickets{
        width: 100%;
        padding: 1.7em 2em;
    }

    .headerTickets h5{
        font-size: 1.8em;
        padding-bottom: 1em;
        text-align: center;
        line-height: 1.3em;
    }

    .headerTickets p{
        width: 100%;
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        justify-content: space-around;
        padding-bottom: 0;
        align-self: center;
        align-items: center;
    }

    .headerTickets span{
        margin: 0;
        padding: .8em;
    }

    /* .headerQuartierTickets{
        width: 40%;
        padding: 1em;
    } */

    /* .headerQuartierTickets h5{
        font-size: 1.5em;
        padding-bottom: 1em;
    } */
}